<template>
    <v-container fluid tag="section">
         <v-data-table
    :headers="headers"
    :items="tasks"
    
    hide-default-footer
    class="elevation-1"
    @click:row="handleItemClick"
  >
    <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Qna</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>
  </v-data-table>
    </v-container>
</template>


<script>
export default {
     data () {
      return {
           headers: [
             {
               text:'Conversation Id',
               value: 'conversationId'
             },
          {
            text: 'Message Activity Id',
            align: 'start',
            value: 'messageActivityId',
          },
          { text: 'Question', value: 'question' },
        
        
        ],
        tasks: [
         
        ],
      }
    },

    methods: {
      handleItemClick (ev) {
        console.log(ev);
        this.$router.push({path:'/message/'+ev.conversationId, query:{messageActivity: ev.messageActivityId, q: ev.question}})
      },
      getTasks(){
        this.$MessageApi.getTasks().then(data=>{
          console.log(data.data);
          this.tasks = data.data.data;
        }).catch(error=>{
          console.log(error);
        })
      }
    },
    mounted(){
        this.getTasks();
    }
}
</script>